<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-11">Stok Özeti</div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="inventorySummaries"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            column-filter
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('list', 'summaryList', item, 'Stok Detay Bilgiler')"
            v-on:refresh="getInventorySummary"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="primary"
                  square
                  size="sm"
                  @click="openModalEvent('list', 'summaryList', item, 'Stok Detay Bilgiler')"
                >
                  Detay
                </CButton>&nbsp;
              </td>
            </template>
            <template #TISORT="{item}">
              <td> {{ item.TISORT ? item.TISORT  : 0 }}</td>
            </template>
            <template #BASIC_TISORT="{item}">
              <td> {{ item.BASIC_TISORT ? item.BASIC_TISORT  : 0 }}</td>
            </template>
            <template #SWEATSHIRT="{item}">
              <td> {{ item.SWEATSHIRT ? item.SWEATSHIRT  : 0 }}</td>
            </template>
            <template #KAPSONLU="{item}">
              <td> {{ item.KAPSONLU ? item.KAPSONLU  : 0 }}</td>
            </template>
            <template #ATLET="{item}">
              <td> {{ item.ATLET ? item.ATLET  : 0 }}</td>
            </template>
            <template #CANTA="{item}">
              <td> {{ item.CANTA ? item.CANTA  : 0 }}</td>
            </template>
            <template #POSTER="{item}">
              <td> {{ item.POSTER ? item.POSTER  : 0 }}</td>
            </template>
            <template #TELEFON_KAPAK="{item}">
              <td> {{ item.TELEFON_KAPAK ? item.TELEFON_KAPAK  : 0 }}</td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ListModal
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="modules"
      :form="form"
      :data="modalProps"
    />
  </CRow>
</template>
<script>
import ListModal from '../components/listModal'

export default {
  name: 'InventorySummary',
  components:{ListModal},
  data() {
    return {
      modalTitle: '',
      actionType: '',
      modules: 'inventory',
      form: 'promotionForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      lastItem: {},
      itemsPerPage: 10,
      activePage: 1,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'Id',_style: 'font-size:12px'},
        {key: 'name', label: 'Stok Alanı', _style: 'font-size:12px'},
        {key: 'TISORT', label: 'Tişört',_style: 'font-size:12px'},
        {key: 'BASIC_TISORT', label: 'Basic Tişört',_style: 'font-size:12px'},
        {key: 'SWEATSHIRT', label: 'Sweatshirt',_style: 'font-size:12px'},
        {key: 'KAPSONLU', label: 'Kapşonlu',_style: 'font-size:12px'},
        {key: 'ATLET', label: 'Atlet',_style: 'font-size:12px'},
        {key: 'CANTA', label: 'Çanta',_style: 'font-size:12px'},
        {key: 'POSTER', label: 'Poster',_style: 'font-size:12px'},
        {key: 'TELEFON_KAPAK', label: 'Telefon_Kapağı',_style: 'font-size:12px'},
        {key: 'toplam', label: 'Toplam',_style: 'font-size:12px'}
      ],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    inventorySummaries(){
      return this.$store.getters.inventorySummaries
    },
    loading(){
      return this.$store.getters.inventoryLoading
    },
    reloadParams() {
      return [
        this.activePage
      ]
    }
  },
  methods: {
    async openModalEvent(actionType, form, data, title, desc){
      if(data){
        let params = {
        "page": 1,
        "start": 0,
        "limit": 10,
        "pos": data.id
      };
      await this.$store.dispatch('inventory_summaryDetail', params)
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    
    rowClicked(item, index) {
      this.lastItem = item;
    },
   
    async getInventorySummary(){
     await this.$store.dispatch('inventorySummary_list')

    }
  },
  created() {
    this.getInventorySummary();
  }
}
</script>

<style>
.tt {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tt, .ttText {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tt:hover .ttText {
  visibility: visible;
}
</style>
